import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby"
import styled from 'styled-components'
import Seo from "../components/Seo";



const PrivacyPage = () => {

  return (
    <Layout>
      <Seo title="POLÍTICA DE PRIVACIDADE | ENOMAC" />
      <PrivacyPageStyled>
        <div className="wrapper">
          <h1>Política de Privacidade</h1>
          <p>
            O presente documento explicita os termos em que a ENOMAC, Lda. com sede na Avenida da Estação, Lote 2, Letra C, Zona Industrial de Oiã 3770-068 Oiã, procede ao tratamento das informações recolhidas e utilizadas nas suas aplicações.
            Todas estas regras estão de acordo com a legislação nacional.<br />
            Enomac, Lda. protege a privacidade dos seus utilizadores bem como os dados pessoais submetidos pelos mesmos, sendo responsável pelo sítio www.enomac.pt (doravante, o “Sítio”)<br /><br />

            <b>1- Informação recolhida</b><br /><br />
            A ENOMAC recolhe dois tipos de informação: informação pessoal e informação anónima. A informação anónima ocorre enquanto o utilizador visita o Sítio e tem como objetivo melhorar o funcionamento do mesmo, incluindo a gestão e priorização de conteúdos.
            Os dados recolhidos têm como finalidades: a identificação dos membros da comunidade/ utilizador; gestão das permissões para efeitos de envio de comunicações de marketing via email; possíveis ações de marketing.<br /><br />

            <b>2- Responsabilidade</b><br /><br />
            A recolha de dados pessoais no Sítio é da total responsabilidade da ENOMAC, Lda. que assume o compromisso de não transmitir de modo nenhum, informações pessoais dos utilizadores do Sítio a terceiros, sem uma devida autorização dos titulares de tais informações.
            A ENOMAC, Lda. não se responsabiliza pelo conteúdo acedido através de qualquer hiperligação que leve o utilizador a navegar fora do domínio www.enomac.ptsempre que tais hiperligações sejam da responsabilidade de terceiros.
            A ENOMAC, Lda. utiliza diversas formas de proteção tecnológica contra ataques à informação pessoal dos utilizadores.<br /><br />

            <b>3- Violação de base de dados</b><br /><br />
            A violação, ou tentativa de violação, de bases de dados obtidos através do Sítio terá como consequência a abertura de processo judicial contra os infratores.<br /><br />

            <b>4- Atualizações/Manutenções de informação pessoal</b><br /><br />
            Ao utilizar o Sítio, o utilizador compromete-se a fornecer e manter os seus dados pessoais atualizados e verdadeiros.<br /><br />
            Pode aceder aos seus dados pessoais, obter uma cópia dos mesmos e/ou pedir a sua atualização ou eliminação, nos termos da lei,
            contactando-nos para a morada Avenida da Estação, Lote 2, Letra C, Zona Industrial de Oiã 3770-068 Oiã ou via e-mail para geral@enomac.pt
          </p>
        </div>
      </PrivacyPageStyled>
    </Layout>
  )
}

export default PrivacyPage

export const query = graphql`
query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "products"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PrivacyPageStyled = styled.div`

    .wrapper {
        padding: 1.5rem 5%;
    }
`
